import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
  MdAdd,
  MdAttachMoney,
  MdLockOutline,
  MdMoneyOff,
} from 'react-icons/md';
import { Button, Checkbox, Form, Image } from 'antd';
import { LuDownload } from 'react-icons/lu';
import {
  CONFIRM_UNLOCK,
  LEAVETYPE,
  MODULE_STATUS,
  MONTHS,
  SORTING,
  YEARS,
} from '../../../constant/constant';
import noDataFound from '../../../Assets/No Data found.png';
import AntdModalComp from '../../ReusableComponents/AntdModalComp';
import { TimeSheetApi } from '../../../Api/TimeSheetApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../../Toast/ToastComponent';
import { useAuth } from '../../../Context/AuthContext';
import Loader from '../../ReusableComponents/Loader';
import stylee from '../../../Styles/TimeSheet.module.css';

import TimeSheetAccordian from '../MyTimeSheet/TimeSheetAccordian';
import TmFilter from '../../ReusableComponents/TmFilter';
import AddLogBdyDnD from '../MyTimeSheet/AddLogBdyDnD';
import UserTimeSheetAccrdn from './UserTimeSheetAccrdn';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import NoDataFound from '../../ReusableComponents/NoDataFound';
import {
  generateCurrentMonth,
  generateCurrentYear,
  generateDays,
  generateYears,
} from '../../../ReusableFn/reusableFn';
import dayjs from 'dayjs';
import { downloadExcel } from '../../ReusableComponents/downloadExcel';
import { confirmationPopup } from '../../ReusableComponents/DeletePopup';
import { FaLock, FaRegCircleXmark } from 'react-icons/fa6';
import ApproveAllPopUp from './ApproveAllPopUp';
import { CheckCircleOutlined } from '@ant-design/icons';
import ApproveMonthTimesheet from './ApproveMonthTimesheet';
import profilePhoto from '../../../Assets/profile.jpg';
import style from '../../../Styles/Header.module.css';
import {
  TbCalendarCancel,
  TbCalendarCheck,
  TbCalendarClock,
  TbSquareRoundedLetterT,
  TbUserExclamation,
} from 'react-icons/tb';
import { PiClockUserBold } from 'react-icons/pi';
import UnlockAllPopup from './UnlockAllPopup';
import AttendanceReport from '../../../Api/attendanceReport';
import BillPopUp from './BillPopUp';
import { ProjectApi } from '../../../Api/ProjectApi';
import Department from '../../../Api/Department';
import { FaArrowLeft } from 'react-icons/fa';

let isEdit = {
  title: 'Add',
  edit: false,
  id: -1,
};
let approveType = '';
let billableType = '';
function UserTimeSheetView() {
  const [modalOpen, setModalOpen] = useState(false);
  const [approveModal, setapproveModal] = useState(false);
  const [approveAllModal, setapproveAllModal] = useState(false);
  const [lockModel, setLockModel] = useState(false);
  const [reportData, setReportData] = useState(undefined);
  const [billablePopUp, setBillablePopUp] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const [loading, setLoading] = useState(false);
  const elementRef = useRef(null);
  const [isInViewport, setIsInViewport] = useState(false);
  const navigate = useNavigate();
  const [timeLog, setTimeLog] = useState({
    timesheetData: [],
    timesheet_details: {},
  });
  const { authState } = useAuth();
  const [form] = Form.useForm();
  const { edit_id } = useParams();

  let userData = JSON.parse(atob(edit_id));

  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: userData?.filterValue?.month || generateCurrentMonth(),
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: userData?.filterValue?.year || generateCurrentYear(),
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
    {
      options: SORTING,
      optionFilterProp: 'label',
      placeholder: 'Sorting',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: SORTING[0].value,
      controlName: 'order',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateDays(),
      optionFilterProp: 'Limit',
      placeholder: 'limit',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: 31,
      controlName: 'limit',
      allowClear: true,
      input: 'select',
    },
    {
      options: projectList,
      optionFilterProp: 'label',
      placeholder: 'Project',
      order: 1,
      col: 2,
      mode: 'multiple',
      maxTagCount: 1,
      defaultValue: null,
      controlName: 'project_search',
      allowClear: true,
      input: 'select',
    },
  ];

  const [filterValue, setFilterValue] = useState({
    month: userData?.filterValue?.month || generateCurrentMonth(),
    year: userData?.filterValue?.year || generateCurrentYear(),
    order: SORTING[0].value,
    limit: 31,
    project_search: [],
  });

  const [monthReport, setMonthReport] = useState([]);

  const handleFilterChange = (obj, type) => {
    setFilterValue((pre) => ({ ...obj }));
    // setFilterValue((pre) => ({ ...pre, [keys]: e }));
  };

  const getLogsList = async () => {
    let reqData = {
      user_id: userData?.employee_id,
      ...filterValue,
      ...userData,
    };
    try {
      const response = await TimeSheetApi.getUserLogs(reqData);
      const res = response?.data;
      if (res?.success) {
        setLoading(true);
        setTimeLog({
          timesheetData: res?.result?.timesheetData || [],
          timesheet_details: res?.result?.timesheet_details || {},
        });
        setMonthReport(res?.result?.monthReport);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleDownload = async () => {
    // username
    let downloadData = {
      fileName: 'TimeSheet.xlsx',
      sheetName: 'June-2024',
      data: [],
    };

    try {
      const response = await TimeSheetApi.downloadData({
        user_id: userData['employee_id'],
        ...filterValue,
      });
      const res = response?.data;
      if (res.success) {
        if (res?.result.length <= 0) {
          toastInfo('No Data Found');
          return;
        }
        let findMonth = res?.result[0]['date'];
        const month =
          new Date(dayjs(findMonth)).toLocaleString('en-US', {
            month: 'long',
          }) || '';
        const year = new Date(dayjs(findMonth)).getFullYear() || '';
        downloadData['fileName'] = `${
          userData['username'] || ''
        }_${month}_${year}_Timesheet.xlsx`;
        downloadData['sheetName'] = `${month}-${year}` || 'sheet 1';
        downloadData['data'] = res?.result || [];
        downloadExcel(downloadData);
      } else {
        toastWarn(res.message);
      }
    } catch (err) {
      toastError(err.message);
    }
  };

  const handleLock = (id) => {
    const deleteIt = async (userRes) => {
      if (userRes !== true) return;
      try {
        const response = await TimeSheetApi.updateUnlock({ id });
        const res = response.data;

        if (res.success) getLogsList();
        else toastWarn(res.message);
      } catch (error) {
        toastError(error.response.data.message);
      }
    };
    confirmationPopup(deleteIt, CONFIRM_UNLOCK);
  };

  const handleClick = (type, id) => {
    switch (type) {
      case 'edit':
        handleEdit(id);
        break;
      case 'update':
        handleUpdate(id);
        break;
      case 'unlock':
        handleLock(id);
        break;

      default:
        break;
    }
  };

  const handleUpdate = async (newTimeLog, showPopUp) => {
    let reqdata = {
      ...userData,
      ...newTimeLog,
      userRoleID: authState?.userRoleID,
      total_hours: Number(newTimeLog?.total_hours) || 0,
      // total_hours: Number((newTimeLog?.total_hours).split(" ")[0]),
      user_id: userData?.employee_id,
      hasProjectFilter: filterValue?.project_search?.length ? true : false,
    };

    try {
      const response = await TimeSheetApi.approveUpdate(reqdata);

      const res = response?.data;
      if (res?.success) {
        getLogsList();
        !showPopUp && toastSuccess(res?.message);
      } else {
        getLogsList();
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleEdit = (id) => {
    isEdit = { title: 'Edit', edit: true, id: id };
    toggleModal();
  };
  const handleAdd = () => {
    isEdit = { title: 'Add', edit: false, id: -1 };
    toggleModal();
  };

  const toggleModal = () => setModalOpen(!modalOpen);
  const openToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleDashed = (type) => {
    approveType = type;
    setapproveModal(!approveModal);
  };
  const approveMonth = () => {
    setapproveAllModal(!approveAllModal);
  };

  const lockToggle = () => {
    setLockModel(!lockModel);
  };

  const billableToggle = (type) => {
    billableType = type;
    setBillablePopUp(!billablePopUp);
  };

  const monthApproveStatus = async (reportObj) => {
    const { is_approved, first_approvers_status, second_approvers_status } =
      reportObj;

    const status =
      first_approvers_status &&
      is_approved === null &&
      second_approvers_status === null
        ? 'Approved by First Approver'
        : second_approvers_status && is_approved
        ? 'Approved'
        : !first_approvers_status && !is_approved && !second_approvers_status
        ? userData?.isFirstApprover
          ? 'Pending'
          : 'Pending by First Approver'
        : 'Pending';

    setReportData(status);
  };

  const getAttendanceReport = async () => {
    try {
      let body = {
        month: filterValue?.month,
        year: filterValue?.year,
        user_id: userData?.employee_id,
        showAll: true,
      };
      const response = await AttendanceReport.get(body);
      if (response?.data?.success) {
        await monthApproveStatus(
          response?.data?.result.length ? response?.data?.result[0] : {},
        );
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const getDropDown = async () => {
    try {
      const response = await ProjectApi.dropDown({
        id: userData?.employee_id,
        userRoleID: userData?.employee_userRoleID,
        showOthers: true,
      });

      if (response?.data?.success) {
        setProjectList(response.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  useEffect(() => {
    getDropDown();
    getLogsList();
    getAttendanceReport();
  }, [filterValue]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInViewport(entry.isIntersecting);
    });
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => observer.disconnect();
  }, []);

  return (
    <div style={{ position: 'relative', top: 0 }}>
      <div
        className={`row m-0 align-items-center ${isInViewport ? '' : 'py-2'}`}
        style={{
          position: `${isInViewport ? 'static' : 'sticky'}`,
          zIndex: `${isInViewport ? 0 : 9}`,
          top: '-12px',
          background: '#f4f6f8',
        }}
      >
        <div className="col-lg-5 ps-lg-0">
          <div className="d-flex align-items-center   ">
            <div className="pe-1">
              <Button
                onClick={() => navigate('/user_timesheet')}
                className="bg-white"
                icon={<FaArrowLeft />}
              ></Button>
            </div>
            <div className="d-flex align-items-center pb-1 m-1 ">
              <div className={`${style.dropdown2}`}>
                <Image
                  className="rounded-circle  width height"
                  src={userData?.profilePicture || profilePhoto}
                  alt=""
                />
              </div>
              <div className="ps-2">
                <div className="d-flex   ">
                  <h5 className="text-nowrap mb-0">{userData?.username}</h5>
                  <small className="ms-1 mt-1">{userData?.employeeCode}</small>
                </div>
                <div className="">
                  {' '}
                  <small>{userData?.designation_name}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 ps-lg-0">
          <div className="row align-items-center m-0 justify-content-lg-end">
            <div className="col-auto px-0 pe-2">
              <div className="d-flex">
                <div className="h5 mt-1">Month Status : </div>
                <div
                  style={{
                    color:
                      reportData == 'Pending by First Approver' ||
                      reportData == 'Pending'
                        ? '#DDA500'
                        : '#4B7E69',
                  }}
                  className=" h5 text-center p-1 ms-1"
                >
                  <span>{reportData}</span>
                </div>
              </div>
            </div>

            {/* <div className="col-auto px-0 pe-2">
              <Button
                type="primary"
                htmlType="button"
                onClick={handleAdd}
                title="Add"
                icon={<MdAdd size={20} />}
              ></Button>
            </div> */}
          </div>
        </div>
      </div>
      <span ref={elementRef}></span>
      <div className="row row-cols-lg-8  mb-2">
        <div className="col-12 col-lg   ">
          <div className="border bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.total_hours ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Total hours Worked
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5  ps-lg-0 d-flex align-items-center justify-content-center">
              <div className={`m-0  ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <TbSquareRoundedLetterT size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg  ps-lg-0  ">
          <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.billable_hours ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Billable Hours
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
              <div className={`m-0 ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <MdAttachMoney size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg  ps-lg-0 ">
          <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.non_billable_hours ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Non Billable Hours
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
              <div className={`m-0 ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <MdMoneyOff size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg  ps-lg-0  ">
          <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.leaves ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Leave Taken
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
              <div className={`m-0 ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <TbUserExclamation size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-lg-8 mb-2">
        <div className="col-12 col-lg   ">
          <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.permissions ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Permission Taken
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
              <div className={`m-0 ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <PiClockUserBold size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg  ps-lg-0  ">
          <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.pending ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Pending
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
              <div className={`m-0 ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <TbCalendarClock size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg  ps-lg-0  ">
          <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.approved ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Approved
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5 ps-lg-0 ps-lg-0 ps-5 d-flex  align-items-center justify-content-center">
              <div className={`m-0 ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <TbCalendarCheck size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg  ps-lg-0  ">
          <div className="border mt-2 mt-lg-0 bg-white d-flex  p-1 px-2 rounded-1 ">
            <div className=" col-lg-10 col-8 ">
              <div className="firstBoxx">
                <p className={`m-0 ${stylee.addcardicon}`}>
                  <b className="primaryColor">
                    {timeLog?.timesheet_details?.rejected ?? 0}
                  </b>
                </p>
              </div>
              <div className="secondBoxx">
                <p className={`mb-0 mt-2 fw-semibold ${stylee.childcolor}`}>
                  Rejected
                </p>
              </div>
            </div>
            <div className="col-lg-2 ps-5 ps-lg-0 d-flex align-items-center justify-content-center">
              <div className={`m-0 ${stylee.circleRadius}`}>
                {' '}
                <span className={` ${stylee.icontranslate}`}>
                  <TbCalendarCancel size={20} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" row  mx-0 ">
        <div className="col-lg-8 d-lg-block d-none  px-0 ">
          <div className="d-flex m-0     ">
            {monthReport?.map((item, idx) => (
              <p
                className="border p-1  me-1  ps-2 rounded-1 statusColor"
                key={idx}
              >
                {item.label} <b className="px-1"> {item.value}</b>
              </p>
            ))}
          </div>
        </div>
        {/* for small screen */}
        <div className="col-lg-8  d-lg-none  px-0 ">
          <div className="d-flex m-0     ">
            <div className=" row m-0 ">
              {monthReport?.map((item, idx) => (
                <div className=" col-6 pe-2 px-0">
                  <p className="border ps-1 rounded-1 statusColor" key={idx}>
                    {item.label} <b className=""> {item.value}</b>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-4 px-0   ">
          <div className="d-flex  justify-content-lg-end justify-content-between ">
            <div className="col-auto px-0 pe-2">
              <Button
                htmlType="button"
                title="Billable All"
                type="dashed"
                onClick={() => billableToggle('billable')}
                icon={<MdAttachMoney size={16} />}
              ></Button>
            </div>
            <div className="col-auto px-0 pe-2">
              <Button
                htmlType="button"
                title="Non Billable All"
                type="dashed"
                onClick={() => billableToggle('Non Billable')}
                icon={<MdMoneyOff size={16} />}
              ></Button>
            </div>
            <div className="col-auto px-0 pe-2">
              <Button
                htmlType="button"
                title="Unlock All"
                type="dashed"
                onClick={lockToggle}
                icon={<MdLockOutline size={16} />}
              ></Button>
            </div>
            <div className=" pe-2">
              <Fragment>
                <Button
                  title="Approve All"
                  htmlType="button"
                  onClick={() => handleDashed('Approve All')}
                  type="dashed"
                  icon={<CheckCircleOutlined size={16} />}
                ></Button>
              </Fragment>
            </div>
            <div className=" px-0 pe-2">
              <Fragment>
                <Button
                  title="Reject All"
                  htmlType="button"
                  onClick={() => handleDashed('Reject All')}
                  type="dashed"
                  icon={<FaRegCircleXmark size={14} />}
                ></Button>
              </Fragment>
            </div>

            <div className=" px-0 pe-2">
              <Button
                htmlType="button"
                title="Download"
                type="dashed"
                onClick={handleDownload}
                icon={<LuDownload size={16} />}
              ></Button>
            </div>
            <div className=" px-0 d-none d-lg-block ">
              <Button
                type="primary"
                title="Approve TimeSheet"
                htmlType="button"
                onClick={approveMonth}
                disabled={userData?.employee_id == authState?.id}
              >
                Approve TimeSheet
              </Button>
            </div>
          </div>
          <div className=" mt-2 px-0 d-sm-none d-sm-block ">
            <Button
              className="w-100"
              type="primary"
              title="Approve TimeSheet"
              htmlType="button"
              onClick={approveMonth}
            >
              Approve TimeSheet
            </Button>
          </div>
        </div>{' '}
      </div>

      {/* style={{ position: "sticky", top: -12, zIndex: 1 }} */}
      {loading && (
        <>
          <div className="mt-2 mt-lg-0">
            <TmFilter
              filterArray={filterArray}
              handleFilterChange={handleFilterChange}
            />
          </div>
          <div className="mt-3">
            {timeLog?.timesheetData.length > 0 && (
              <UserTimeSheetAccrdn
                getAttendanceReport={getAttendanceReport}
                timeLog={timeLog.timesheetData}
                handleClick={handleClick}
                handleUpdate={handleUpdate}
                getLogsList={getLogsList}
              />
            )}
            {!timeLog?.timesheetData.length > 0 && <NoDataFound />}
          </div>
        </>
      )}
      {!loading && <Loader />}
      <Fragment>
        <AntdModalComp
          width="90%"
          toggleModal={toggleModal}
          modalOpen={modalOpen}
          modalHeader={<></>}
          modalBody={<AddLogBdyDnD toggleModal={toggleModal} isEdit={isEdit} />}
          closable={false}
          maskClosable={false}
        />
      </Fragment>
      <span>
        <AntdModalComp
          width="40%"
          toggleModal={handleDashed}
          modalOpen={approveModal}
          modalHeader={
            <>
              {(approveType === 'Approve All' && (
                <span>Are you sure you want to Approve?</span>
              )) ||
                (approveType === 'Reject All' && (
                  <span>Are you sure you want to Reject?</span>
                ))}
            </>
          }
          modalBody={
            <ApproveAllPopUp
              approveType={approveType}
              toggleModal={handleDashed}
              getLogsList={getLogsList}
              filterValue={filterValue}
              getAttendanceReport={getAttendanceReport}
              userData={{
                ...userData,
                userRoleID: authState?.userRoleID,
                user_id: authState?.id,
              }}
            />
          }
          closable={true}
          maskClosable={true}
        />
      </span>
      <span>
        <AntdModalComp
          width="40%"
          toggleModal={approveMonth}
          modalOpen={approveAllModal}
          modalHeader={<>Confirm Month Approve?</>}
          modalBody={
            <ApproveMonthTimesheet
              userData={{
                ...userData,
                userRoleID: authState?.userRoleID,
                id: authState?.id,
              }}
              statusData={{
                monthReport: monthReport,
                timesheet_details: timeLog.timesheet_details,
              }}
              toggleModal={approveMonth}
              getLogsList={getLogsList}
              getAttendanceReport={getAttendanceReport}
              filterValue={filterValue}
            />
          }
          closable={true}
          maskClosable={true}
        />
      </span>
      <span>
        <AntdModalComp
          width="40%"
          toggleModal={lockToggle}
          modalOpen={lockModel}
          modalHeader={<>Confirm Unlock?</>}
          modalBody={
            <UnlockAllPopup
              filterValue={filterValue}
              getAttendanceReport={getAttendanceReport}
              getLogsList={getLogsList}
              userData={{ ...userData, userRoleID: authState?.userRoleID }}
              toggleModal={lockToggle}
            />
          }
          closable={true}
          maskClosable={true}
        />
      </span>
      <span>
        <AntdModalComp
          width="40%"
          toggleModal={billableToggle}
          modalOpen={billablePopUp}
          modalHeader={<>Confirm Action?</>}
          modalBody={
            <BillPopUp
              billableType={billableType}
              filterValue={filterValue}
              getAttendanceReport={getAttendanceReport}
              getLogsList={getLogsList}
              userData={{ ...userData, userRoleID: authState?.userRoleID }}
              toggleModal={billableToggle}
            />
          }
          closable={true}
          maskClosable={true}
        />
      </span>
    </div>
  );
}

export default UserTimeSheetView;
